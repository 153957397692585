import './App.css';

function Timeline(props: { data: { time: React.ReactNode, value: React.ReactNode }[] }) {
    const { data } = props;
    return (
        <div style={{ marginTop: 36 }}>
            {data.map((item, index) => (
                <div key={index} className='timeline'>
                    <div className='timevalue'>{item.value}</div>
                    <div className='timedot'></div>
                    <div className='timetime'>{item.time}</div>
                </div>
            ))}
        </div>
    );
}

export default Timeline;
